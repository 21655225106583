<template>
  <div>
    <h3>History</h3>
    <div v-if="showButton">
      <b-button class="mr-2" variant="outline-primary" v-on:click="loadHistory">show workflow history</b-button>
    </div>
    <div v-if="!loading">
      <div class="my-4" v-for="item in history" :key="'history-' + item.id">
        <h5>{{moment(item.created_at).format('YYYY-MM-DD HH:mm')}}</h5>
        <div class="mb-2">
          <history-sidebar :version="item" />
          {{item.stage_created_by}} moved from <strong>{{workflow.find(x => x.id === item.stage_from).name}}</strong>
          to <strong>{{workflow.find(x => x.id === item.stage_to).name}}</strong>
          after {{item.duration}}
        </div>
        <b-alert v-if="item.stage_comments.length > 0" show variant="info">
          <div><strong>Comments</strong></div>
          <div v-html="item.stage_comments"></div>
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import workflow from '@/workflows/article'

import HistorySidebar from '@/components/workflow/WorkflowHistorySidebar'

export default {
  components: {
    HistorySidebar
  },
  computed: {
    article: {
      get () {
        return this.$store.state.article
      },
      set (payload) {
        this.$store.commit('setArticle', payload)
      }
    }
  },
  created: async function () {
  },
  data () {
    return {
      history: [],
      loading: true,
      moment: moment,
      showButton: true,
      showHistory: false,
      workflow: workflow
    }
  },
  methods: {
    loadHistory: async function () {
      const history = await this.$Amplify.API.get('cosmos', `/article/${this.article.id}/workflow/history`)
      const minute = 1000 * 60
      const hour = minute * 60
      const day = hour * 24
      _.each(history, x => {
        if (x.stage_duration < hour) {
          x.duration = _.round(x.stage_duration / minute, 1) + ' minutes'
        } else if (x.stage_duration < day) {
          x.duration = _.round(x.stage_duration / hour, 1) + ' hours'
        } else {
          x.duration = _.round(x.stage_duration / day, 1) + ' days'
        }
      })
      this.history = history
      this.showButton = false
      this.showHistory = true
      this.loading = false
    }
  }
}
</script>

<style>
</style>
