<template>
  <div>
        <div class="editor-comments">
          <!-- <editor-menu-bar :editor="editor" v-slot="{ isActive }"> -->
            <div class="menubar">
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('bold') }" @click="editor.chain().toggleBold().focus().run()">
                <div class="icon">
                  <b-icon-type-bold title="Bold"></b-icon-type-bold>
                </div>
              </button>
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('italic') }" @click="editor.chain().toggleItalic().focus().run()">
                <div class="icon">
                  <b-icon-type-italic title="Italic"></b-icon-type-italic>
                </div>
              </button>
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('underline') }" @click="editor.chain().toggleUnderline().focus().run()">
                <div class="icon">
                  <b-icon-type-underline title="Underline"></b-icon-type-underline>
                </div>
              </button>
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('paragraph') }" @click="editor.chain().toggleParagraph().focus().run()">
                <div class="icon">
                  <b-icon-paragraph title="Paragraph"></b-icon-paragraph>
                </div>
              </button>
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('bullet_list') }" @click="editor.chain().toggleBulletList().focus().run()">
                <div class="icon">
                  <b-icon-list-ul title="Bullet List"></b-icon-list-ul>
                </div>
              </button>
              <button class="menubar__button" :class="{ 'is-active': editor.isActive('ordered_list') }" @click="editor.chain().toggleOrderedList().focus().run()">
                <div class="icon">
                  <b-icon-list-ol title="Ordered List"></b-icon-list-ol>
                </div>
              </button>
            </div>
          <!-- </editor-menu-bar> -->
        </div>
        <div class="editor-comments">
          <editor-content class="editor__content" :editor="editor" />
        </div>
  </div>
</template>
<script>
import { BIconParagraph, BIconTypeBold, BIconTypeItalic, BIconTypeUnderline, BIconListOl, BIconListUl } from 'bootstrap-vue'
import { Editor, EditorContent } from '@tiptap/vue-2'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Blockquote from '@tiptap/extension-blockquote'
import BulletList from '@tiptap/extension-bullet-list'
import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import History from '@tiptap/extension-history'

export default {
  components: { BIconParagraph, BIconTypeBold, BIconTypeItalic, BIconTypeUnderline, EditorContent, BIconListOl, BIconListUl },
  computed: {
  },
  props: ['comment'],
  created: async function () {
    this.editor = new Editor({
      extensions: [
        Document,
        Paragraph,
        Text,
        Blockquote,
        BulletList,
        HardBreak,
        ListItem,
        OrderedList,
        TaskItem,
        TaskList,
        Link,
        Bold,
        Italic,
        Underline,
        History,
        Heading
      ],
      content: this.body,
      onUpdate: ({ getJSON, getHTML }) => {
        const html = this.editor.getHTML()
        this.$emit('input', html)
      }
    })
  },
  data () {
    return {
      editor: null
    }
  },
  beforeDestroy: function () {
    this.editor.destroy()
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style lang="scss">
$color-black: #000000;
$color-white: #ffffff;
$color-grey: #dddddd;

.editor-comments > .icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  &__svg {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.editor-comments > .menubar {

  border: 0px;
  border-style: solid;
  font-size: 15px;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }

  &.is-focused {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.2s, opacity 0.2s;
  }

  &__button {
    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $color-black;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color-black, 0.05);
    }

    &.is-active {
      background-color: rgba($color-black, 0.1);
    }
  }

  span#{&}__button {
    font-size: 13.3333px;
  }
}

.editor-comments > .editor__content {
  border: 0px;
  background-color: white;
  height: 200px;
  overflow-y: scroll;
  padding: 10px;
}

.ProseMirror {
  border: 0px;
  border-color: white;
  outline-width: 0px;
  outline-style: none;
}

blockquote {
  border-left: 3px solid rgba($color-black, 0.1);
  color: rgba($color-black, 0.8);
  padding-left: 0.8rem;
  font-style: italic;

  p {
    margin: 0;
  }
}
</style>
