<template>
  <span>
    <b-button variant="outline-primary" v-b-toggle:[uid]>show this version</b-button>
     <b-sidebar :id="uid" width="90%" title="Version history" text-variant="black" shadow>
      <div class="px-3 py-2">
        <h5>{{moment(version.created_at).format('YYYY-MM-DD HH:mm')}}</h5>
        <div>
          {{version.stage_created_by}} moved from <strong>{{workflow.find(x => x.id === version.stage_from).name}}</strong>
          to <strong>{{workflow.find(x => x.id === version.stage_to).name}}</strong>
          after {{version.duration}}
        </div>
        <div v-html="version.stage_comments"></div>
        <b-button v-if="!showMarkUp" class="mr-2" variant="outline-primary" v-on:click="loadMarkUp">show markup current vs this old version</b-button>
        <b-button v-if="showMarkUp" class="mr-2" variant="outline-primary" v-on:click="showMarkUp = false">no markup</b-button>
        <b-button class="mr-2" variant="outline-primary" v-on:click="copyToEditor">copy this old version to editor</b-button>
        <h1>{{version.title}}</h1>
        <div v-if="showMarkUp">
          <div v-html="markupSummary"></div>
          <br />
          <div v-html="markup"></div>
          <div v-html="markupImplications"></div>
        </div>
        <div v-if="!showMarkUp">
          {{version.summary}}
          <br /><br />
          <div v-html="version.body"></div>
          <div v-html="version.impliactions"></div>
        </div>
      </div>
    </b-sidebar>
  </span>
</template>

<script>
import moment from 'moment'

import htmldiff from '@/libs/htmldiff'
import workflow from '@/workflows/article'

export default {
  props: ['version'],
  components: {
  },
  computed: {
    article: {
      get () {
        return this.$store.state.article
      },
      set (payload) {
        this.$store.commit('setArticle', payload)
      }
    },
    triggerArticleEditorRefresh: {
      get () {
        return this.$store.state.triggerArticleEditorRefresh
      },
      set (payload) {
        this.$store.commit('setTriggerArticleEditorRefresh', payload)
      }
    },
    uid: function () {
      return 'articlesidebar-' + this.version.id
    }
  },
  created: async function () {
    const version = {
      summary: this.version.summary ? this.version.summary : ' ',
      implications: this.version.implications ? this.version.implications : ' '
    }
    const article = {
      summary: this.article.summary ? this.article.summary : ' ',
      implications: this.article.implications ? this.article.implications : ' '
    }
    this.markup = htmldiff(this.version.body, this.article.body)
    this.markupSummary = htmldiff(version.summary, article.summary)
    this.markupImplications = htmldiff(version.implications, article.implications)
  },
  data () {
    return {
      markup: '',
      markupSummary: '',
      markupImplications: '',
      moment: moment,
      showMarkUp: false,
      workflow: workflow
    }
  },
  methods: {
    copyToEditor: function () {
      const article = this.article
      article.body = this.version.body
      article.summary = this.version.summary
      article.implications = this.version.implications
      this.article = null
      this.article = article
      this.triggerArticleEditorRefresh = Date.now()
      this.$root.$emit('bv::toggle::collapse', this.uid)
    },
    loadMarkUp: function () {
      const version = {
        summary: this.version.summary ? this.version.summary : ' ',
        implications: this.version.implications ? this.version.implications : ' '
      }
      const article = {
        summary: this.article.summary ? this.article.summary : ' ',
        implications: this.article.implications ? this.article.implications : ' '
      }
      this.markup = htmldiff(this.version.body, this.article.body)
      this.markupSummary = htmldiff(version.summary, article.summary)
      this.markupImplications = htmldiff(version.implications, article.implications)
      this.showMarkUp = true
    }
  }
}
</script>

<style>
  del {
    background-color: orangered;
  }
  ins {
    background-color: lightgreen;
  }
</style>
