<template>
<div>
  <b-container fluid>
    <b-row class="mb-4" v-if="!permissions.workflow">
      <b-col>
        Access only for RRIC Admins
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permissions.workflow">
      <b-col>
        <editor :permissions="permissions" />
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permissions.workflow">
      <b-col>
        <h3>Workflow: <strong>Edit</strong> stage</h3>
        <div class="mb-2">{{this.article.stage_created_by}} moved to this stage on {{moment(this.article.stage_start).format('YYYY-MM-DD HH:mm')}}</div>
        <b-alert v-if="article.stage_comments" show variant="info">
          <div><strong>Comments</strong></div>
          <div v-html="article.stage_comments"></div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permissions.editArticle">
      <b-col>
        <b-button v-if="!showEditor" class="mr-2" variant="outline-primary" v-on:click="showEditor = true">add comments to this stage</b-button>
        <div v-if="showEditor"><editor-comments v-model="comment" /></div>
      </b-col>
      <b-col>
        <label for="example-datepicker">set due date for the next step</label>
        <b-form-datepicker id="workflow-due-date" v-model="dueDate" class="mb-2"></b-form-datepicker>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permissions.editArticle">
      <b-col>
        <b-button variant="danger" @click="moveToDeleted">Move to deleted</b-button>
      </b-col>
      <b-col>
        <b-button variant="success" @click="moveToReview">Move to review</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <workflow-history />
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import Editor from '@/components/workflow/article/Editor'
import EditorComments from '@/components/workflow/article/EditorComments'
import WorkflowHistory from '@/components/workflow/WorkflowHistory'

export default {
  components: {
    Editor,
    EditorComments,
    WorkflowHistory
  },
  computed: {
    article: {
      get () {
        return this.$store.state.article
      },
      set (payload) {
        this.$store.commit('setArticle', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    },
    workflowStage: {
      get () {
        return this.$store.state.workflowStage
      },
      set (payload) {
        this.$store.commit('setWorkflowStage', payload)
      }
    }
  },
  created: function () {
    this.permissions.workflow = ac.can(this.user.acgroups).readAny('workflow').granted
    this.permissions.editArticle = ac.can(this.user.acgroups).readAny('articleWorkflowEdit').granted
    if (!this.permissions.workflow) {
      this.$router.push({ name: 'Start' })
    }
  },
  data () {
    return {
      comment: '',
      dueDate: null,
      moment: moment,
      permissions: {},
      showEditor: false
    }
  },
  methods: {
    moveToDeleted: async function () {
      const params = {
        body: {
          article: this.article,
          stage: 'delete',
          stage_comments: this.comment,
          stage_created_by: this.user.username,
          stage_due_date: this.dueDate
        }
      }
      const article = this.article
      article.stage_comments = this.comment
      article.updated_at = moment()
      article.stage_start = moment()
      this.article = article
      await this.$Amplify.API.put('cosmos', `/article/workflow/move`, params)
      this.$router.push({ name: 'WorkflowArticles' })
    },
    moveToReview: async function () {
      const params = {
        body: {
          article: this.article,
          stage: 'review',
          stage_comments: this.comment,
          stage_created_by: this.user.username,
          stage_due_date: this.dueDate
        }
      }
      const article = this.article
      article.stage_comments = this.comment
      article.updated_at = moment()
      article.stage_start = moment()
      this.article = article
      await this.$Amplify.API.put('cosmos', `/article/workflow/move`, params)
      this.workflowStage = 'review'
    }
  }
}
</script>

<style>
</style>
